.btn {
  line-height: 1.499;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  cursor: pointer;
  user-select: none;
  touch-action: manipulation;
  padding: 4px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.65);
  background-color: transparent;
  border: none;
  border-color: rgb(217, 217, 217);
  flex: 0 0 auto;
  width: 32px;
  height: 32px;
}

.btn:active,
.btn:hover,
.btn:focus {
  text-decoration: none;
  outline: none;
  border: 2px solid var(--primary-color);
  background-color: #f3f3f3;
}

.btn:hover {
  color: var(--primary-light-color);
}

.btn:active {
  color: var(--primary-dark-color);
}

.btn:disabled,
.btn[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #eee;
}

.btn[disabled]:hover {
  border: none;
  color: rgba(0, 0, 0, 0.25);
  background-color: #eee;
}

.danger:hover,
.danger:focus {
  color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
}

.danger:active {
  color: var(--secondary-dark-color);
  border: 2px solid var(--secondary-dark-color);
}

.ghost {
  color: rgba(255, 255, 255, 0.8);
  background-color: transparent;
}

.ghost:hover,
.ghost:focus {
  color: rgba(255, 255, 255, 0.85);
  background-color: transparent;
}

.ghost:active {
  color: rgba(255, 255, 255, 1);
  background-color: transparent;
}

.ghost:disabled,
.ghost[disabled] {
  border: none;
  color: rgba(255, 255, 255, 0.55);
}
