.input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.input:focus {
  border: 2px solid var(--primary-color);
  /* padding adjustment prevents added border from shifting text */
  padding-left: 10px;
  padding-right: 10px;
  outline: 0;
}

.input:hover {
  border: 2px solid var(--primary-color);
  /* padding adjustment prevents added border from shifting text */
  padding-left: 10px;
  padding-right: 10px;
}

.danger {
  border-color: var(--secondary-color);
  box-shadow: inset 0 1px 1px var(--secondary-color-30);
}

.danger:focus {
  border: 2px solid var(--secondary-color);
  outline: 0;
}

.danger:hover {
  border-color: var(--secondary-color);
}

.danger::placeholder {
  color: var(--secondary-color);
}
