/* hide tauchart's ui for export options */
.tau-chart__export {
  display: none;
}

/* react splitpane resizer */
.Resizer.vertical:hover {
  border-left: 5px solid var(--primary-color-90);
  border-right: 5px solid var(--primary-color-90);
}

/* react splitpane resizer */
.Resizer.horizontal:hover {
  border-top: 5px solid var(--primary-color-90);
  border-bottom: 5px solid var(--primary-color-90);
}

.Resizer:hover {
  transition: all 0.3s ease;
}

/* QueryResultDataTable react-window/react-draggable implementation */
.DragHandle {
  width: 8px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  cursor: col-resize;
  color: rgba(0, 0, 0, 0.2);
}
.DragHandle:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.DragHandleActive,
.DragHandleActive:hover {
  color: rgba(0, 0, 0, 0.6);
  z-index: 3;
}

.DragHandleIcon {
  flex: 0 0 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* 
    Translate needs to be overridden to make this work like it originally did. 
    The element being dragged is positioned with transform as user drags, 
    and it is not reset until drag is stopped.

    This is problematic because while the user drags and the element is being translated, 
    the grid is resizing the column, causing the handle to drag twice as fast in the direction the user is dragging.

    To address this a couple options to explore: 
    * The implementation may need to switch to use DraggableCore, 
    * Only resize on drag stop
    * Lift drag handle out of column being resized
  */
  transform: translate(0px, 0px) !important;
}

/* Missing Ace diff editor style for highlighting code changes */
.codeMarker {
  background: #fff677;
  position: absolute;
  z-index: 20;
}
