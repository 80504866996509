.text {
  margin-right: 0.5rem;
  white-space: nowrap;
}

.AlertIcon {
  margin-right: 0.5rem;
  position: relative;
  top: 3px;
}
